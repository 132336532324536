/**
 * Ter Steege Bouw
 *
 * @author Robuust
 * @author Rutger Bakker <rutger@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';

/**
 * Video controller
 */
export default class extends Controller {
  static targets = ['video'];

  /**
   * Mount
   */
  connect() {
    if (this.hasVideoTarget) {
      this.videoTarget.addEventListener('play', this.playing.bind(this));
    }
  }

  /**
   * Trigger video play
   */
  playVideo() {
    if (this.hasVideoTarget) this.videoTarget.play();
  }

  /**
   * Video playing
   */
  playing() {
    this.element.classList.add('video-playing');
    this.videoTarget.controls = true;
  }

  /**
   * Unmount
   */
  disconnect() {
    if (this.hasVideoTarget) {
      this.videoTarget.removeEventListener('play', this.playing.bind(this));
    }
  }
}
