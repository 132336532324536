/**
 * Ter Steege Bouw
 *
 * @author Robuust
 * @author Jurriën Peters <jurrien@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';

/**
 * Controller class to handle download button functionality.
 *
 * @class
 * @extends Controller
 */
export default class extends Controller {
  static targets = ['button'];

  /**
   * Change button href based on the selected value.
   *
   * @param {Event} e - The event object from the select action.
   */
  select(e) {
    this.buttonTarget.href = e.target.value;
  }
}
