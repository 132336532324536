// Stimulus
import { Application } from '@hotwired/stimulus';
import { registerControllers } from 'stimulus-vite-helpers';

// Turbo
import * as Turbo from '@hotwired/turbo';

// Assets
import '../assets/img/favicon/apple-touch-icon.png';
import '../assets/img/favicon/favicon-32x32.png';
import '../assets/img/favicon/favicon-16x16.png';
import '../assets/img/svg/icon-mail.svg';
import '../assets/img/svg/icon-chevron-right.svg';
import '../assets/img/svg/icon-instagram.svg';
import '../assets/img/svg/icon-linkedin.svg';
import '../assets/img/svg/icon-facebook.svg';
import '../assets/img/svg/icon-phone.svg';
import '../assets/img/svg/icon-external.svg';
import '../assets/img/svg/icon-play.svg';
import '../assets/img/svg/icon-arrow-down.svg';
import '../assets/img/svg/icon-chevron-down.svg';
import '../assets/img/svg/icon-compare.svg';
import '../assets/img/svg/icon-chevron.svg';
import '../assets/img/svg/icon-map.svg';
import '../assets/img/svg/icon-location.svg';
import '../assets/img/svg/icon-location-hover.svg';
import '../assets/img/svg/icon-buildings.svg';
import '../assets/img/svg/icon-file.svg';
import '../assets/img/svg/icon-hammer.svg';
import '../assets/img/svg/icon-masonry.svg';
import '../assets/img/svg/icon-pin.svg';
import '../assets/img/svg/icon-website.svg';

// Logo's
import '../assets/img/logo/logo-tersteege.svg';
import '../assets/img/logo/logo-tersteege-blue.png';
import '../assets/img/logo/footer-logo.svg';

// Start Stimulus
const application = Application.start();
const controllers = import.meta.glob('./**/*_controller.js', { eager: true });
registerControllers(application, controllers);

// Start Turbo
Turbo.start();

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log('HMR'); // eslint-disable-line no-console
  });
}
