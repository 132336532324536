/**
 * Ter Steege Bouw
 *
 * @author Robuust
 * @author Jurriën Peters <jurrien@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';

/**
 * Simple toggle controller
 */
export default class extends Controller {
  static targets = ['input'];

  static classes = ['active'];

  /**
   * Toggle target class.
   */
  toggle() {
    this.element.classList.toggle(this.activeClass);

    if (this.hasInputTarget) this.inputTarget.focus();
  }
}
