/**
 * Ter Steege Bouw
 *
 * @author Robuust
 * @author Rutger Bakker <rutger@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';

/**
 * Header controller.
 */
export default class extends Controller {
  static targets = ['menuItem'];

  /**
   * On scroll.
   */
  onScroll() {
    this.element.classList.toggle('has-scrolled', window.scrollY >= 50);
  }

  /**
   * Toggle mobile menu.
   */
  toggleMobileMenu() {
    this.element.classList.toggle('menu-open');
    this.element.classList.toggle('has-scrolled');
    this.menuItemTargets.forEach((item) => item.classList.remove('is-expanded'));
  }

  /**
   * Toggle mobile submenu.
   *
   * @param {Event} event
   */
  toggleMobileSubmenu(event) {
    this.menuItemTargets.forEach((item) => item.classList.toggle('is-expanded', item === event.target));
  }
}
