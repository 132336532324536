/* eslint-disable class-methods-use-this */
/**
 * Ter Steege Bouw
 *
 * @author Robuust
 * @author Bob Olde Hampsink <bob@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';

const { mapkit } = window;

/**
 * Controller for establishments.
 */
export default class extends Controller {
  /**
   * @return {Array}
   */
  static targets = ['list', 'establishment', 'map'];

  /**
  * @return {Array}
  */
  static classes = ['active'];

  /**
   * @var {Map}
   */
  map;

  /**
   * @var {Region}
   */
  region;

  /**
   * @var {Array}
   */
  annotations = [];

  /**
   * Initialize map.
   */
  initialize() {
    mapkit.init({
      authorizationCallback(done) {
        done(import.meta.env.VITE_MAPKIT_TOKEN);
      },
    });
  }

  /**
   * Connect map.
   *
   * @param {HTMLElement} target
   */
  mapTargetConnected(target) {
    this.map = new mapkit.Map(target);
  }

  /**
   * Destroy map.
   */
  mapTargetDisconnected() {
    if (this.map !== undefined) {
      this.map.destroy();
    }
  }

  /**
   * Set markers for list.
   *
   * @param {HTMLElement} target
   */
  listTargetConnected(target) {
    const url = new URL(target.dataset.url);
    this.setMarkers(url);
  }

  /**
   * Render markers.
   *
   * @param {URL} url
   */
  async setMarkers(url) {
    // Get markers
    const response = await fetch(url);
    const establishments = await response.json();

    // Set markers
    this.map.removeItems(this.annotations);
    this.annotations = [];
    for (let i = 0; i < establishments.length; i += 1) {
      const establishment = establishments[i];
      const coords = new mapkit.Coordinate(establishment.latitude, establishment.longitude);

      const annotation = new mapkit.Annotation(coords, this.getMarker, {
        data: { ...establishment },
        animates: false,
        selected: false,
      });

      if (this.hasActiveClass) {
        annotation.addEventListener('select', (e) => {
          this.annotations.forEach((a) => {
            a.element.classList.toggle(this.activeClass, a.data.id === e.target.data.id);
          });

          this.establishmentTargets.forEach((es) => {
            es.classList.toggle(this.activeClass, +es.dataset.id === e.target.data.id);
          });

          const span = new mapkit.CoordinateSpan(0.01);
          const region = new mapkit.CoordinateRegion(e.target.coordinate, span);
          this.map.setRegionAnimated(region);
        });

        annotation.addEventListener('deselect', () => {
          this.annotations.forEach((a) => {
            a.element.classList.remove(this.activeClass);
          });

          this.establishmentTargets.forEach((es) => {
            es.classList.remove(this.activeClass);
          });

          this.map.setRegionAnimated(this.region);
        });
      }

      this.annotations.push(annotation);
    }
    this.map.showItems(this.annotations);

    // Cache original region
    this.region = this.map.region;
  }

  /**
   * Create marker.
   *
   * @return {Element}
   */
  getMarker() {
    const element = document.createElement('div');
    element.className = 'establishment-marker';

    return element;
  }

  /**
   * Toggle target class.
   *
   * @param {Event} e
   */
  toggle(e) {
    const target = e.target.closest('[data-id]');
    const active = target.classList.toggle(this.activeClass);

    this.annotations.forEach((a) => {
      a.element.classList.toggle(this.activeClass, a.data.id === +target.dataset.id && active);

      if (a.data.id === +target.dataset.id) {
        if (active) {
          const span = new mapkit.CoordinateSpan(0.01);
          const region = new mapkit.CoordinateRegion(a.coordinate, span);
          this.map.setRegionAnimated(region);
        } else {
          this.map.setRegionAnimated(this.region);
        }
      }
    });
  }

  /**
   * Hover target class.
   *
   * @param {Event} e
   */
  hover(e) {
    const target = e.target.closest('[data-id]');
    const active = e.type === 'mouseenter' || target.classList.contains(this.activeClass);

    this.annotations.forEach((a) => {
      a.element.classList.toggle(this.activeClass, a.data.id === +target.dataset.id && active);
    });
  }
}
