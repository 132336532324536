/**
 * Ter Steege Bouw
 *
 * @author Robuust
 * @author Jurriën Peters <jurrien@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';
import { Swiper } from 'swiper';
import {
  Autoplay,
  EffectFade,
  Navigation,
  Pagination,
} from 'swiper/modules';

Swiper.use([Autoplay, EffectFade, Navigation, Pagination]);

/**
 * Swiper controller.
 */
export default class extends Controller {
  static targets = ['wrapper'];

  static values = {
    options: Object,
    type: {
      type: String,
      default: 'default',
    },
    titles: Array,
  };

  swiper;

  /**
   * Init.
   */
  initialize() {
    this.swiper = new Swiper(this.wrapperTarget, {
      slidesPerView: 'auto',
      ...this.optionsValue,
    });
  }

  /**
   * Mount.
   */
  connect() {
    this.swiper.enable();

    if (this.typeValue === 'bullets') {
      this.customizeBullets();
    }
  }

  /**
  * Disconnect swiper.
  */
  disconnect() {
    this.swiper.destroy(true);
    this.swiper = undefined;
  }

  /**
  * Show title of slide in the bullets.
  */
  customizeBullets() {
    this.swiper.pagination.bullets.forEach((bullet, i) => {
      const b = bullet;
      b.innerHTML = this.titlesValue[i];
    });
  }
}
